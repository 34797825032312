import Component from 'vue-class-component'
import { Prop } from 'vue-property-decorator'
import { CommonBaseMixin } from '../../components/mixins/base'

@Component
export default class ErrorPage extends CommonBaseMixin {
  @Prop({ type: String }) readonly errorCode!: string
  @Prop({ type: Boolean }) readonly hideHeader!: boolean

  get forceHideHeader () {
    return this.$router.currentRoute.path.includes(this.errorCode)
  }
}
